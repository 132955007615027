import { GET_DASHBOARD } from '../reducers/dashboard';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import { creditBreakdown } from 'utils/credit-breakdown';

export default class DashboardRedux {
    static get = () => async (dispatch, getState) => {
        const user = getState().user;
        try {
            const url = `/forms?type=ERC&ReferralCode=${user?.ReferralPartner?.code}`;

            const response = await axios.get(`${ApiResource.endpoint}/partners${url}`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });

            const forms = response.data;
            forms.items.forEach((item) => {
                item.breakdown = creditBreakdown(item);
            });

            dispatch({
                payload: { forms },
                type: GET_DASHBOARD,
            });

            return response;
        } catch (error) {
            throw error;
        }
    };
}
