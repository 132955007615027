export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;
export const SYNC_TIME = MINUTE * 5;
export const ContactNumber = '+1 (916) 518-1010';
export const CalendlyLink = 'https://calendly.com/the-relief-consultants/erc-intro-call';
export const EDDOnceHub = 'https://go.oncehub.com/TRCTeamPage';
export const SupportEmail = 'support@thereliefconsultants.com';
export const TextLine = '+1 (916) 518-1010';
export const BlogLink = 'https://medium.com/@the-relief-consultants';
export const LinkedInLink = 'https://www.linkedin.com/company/the-relief-consultants/';
export const OtherNumber = '+1 (858) 367-7049';
export const RPInbox = '+1 (858) 869-0932';

export const STATUS = [
    'Application in Progress',
    'Awaiting Documents',
    'Processing Documents',
    'Signatures',
    'Awaiting IRS Payment',
    'Completed',
];

export const STATUS_TO_READABLE = {
    'Lead - In Progress': 'Lead Submitted - Thank you',
    'Lead - Not Qualified': 'Lead Not Eligible',
    'Closed - Awaiting Documents': 'Credit in Progress',
    'Closed - Processing Documents': 'Credit in Progress',
    'Closed - Signatures Required': 'Credit in Progress',
    'Closed - Awaiting Payments': 'Credit Submitted',
    'Paid - Unpaid': 'Credit Submitted',
    'Paid - Unresponsive Unpaid': 'Credit Submitted',
    'Paid - Partial Payment': 'Credit Submitted',
    'Paid - In Full': 'Credit Received',
};

export const STORAGE_KEY = {
    RESYNC_VIDEO: 'resync-videos',
    NEW_COURSES: 'new-courses',
};
