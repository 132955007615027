// UI
import { Search } from '@mui/icons-material';
import { Box, Button, Container, InputAdornment, Paper, Skeleton, TablePagination, TextField, Typography } from '@mui/material';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import DownStreamRedux from 'redux/actions/downstream';
import { CiSquarePlus } from 'react-icons/ci';
import DownstreamItem from './DownstreamItem/DownstreamItem';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

const TableHeaders = ['Name', 'Applications', 'Total Credit', 'Started On'];
const translation = {
    Name: 'name',
    Applications: 'applicationCount',
    'Total Credit': 'totalCredit',
    'Started On': 'createdAt',
};

export default function Downstream() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector(({ user }) => ({ user }));
    const dispatch = useDispatch();
    const { downstream } = useSelector(({ downstream }) => ({ downstream }));
    const [credit, setCredit] = useState({});
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Started On',
    });

    useEffect(() => {
        getDownstream();
        getStats();
    }, [sort, page, perPage]);

    async function getStats() {
        // try {
        //     const creditResponse = await axios.get(`${ApiResource.endpoint}/partners/downstream/credit`, {
        //         headers: {
        //             'x-auth': user.authToken,
        //         },
        //     });
        //     setCredit(creditResponse.data);
        // } catch (error) {
        //     console.log(error);
        // }
    }

    async function getDownstream() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];
            await dispatch(
                DownStreamRedux.get({
                    perPage,
                    page,
                    order: sort.order,
                    orderBy: orderByTl,
                    search,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Paper className={classes.paper}>
                    <Box className={classes.tableActions}>
                        <Box className={classes.stats}>
                            {/* <Box className={classes.stat}>
                                <Typography variant="font3" sx={{ fontWeight: '400', marginBottom: 1 }}>
                                    My Credit
                                </Typography>
                                <Typography color="success.main" variant="h5" sx={{ fontWeight: '600', marginBottom: 1 }}>
                                    $
                                    {user.ReferralPartner.isMaster
                                        ? credit.myMasterSum?.numberWithCommas?.(2) ?? '0.00'
                                        : credit.myTotalSum?.numberWithCommas?.(2) ?? '0.00'}
                                </Typography>
                            </Box>
                            <Box className={classes.stat}>
                                <Typography variant="font3" sx={{ fontWeight: '400', marginBottom: 1 }}>
                                    My Downstreams Credit
                                </Typography>
                                <Typography color="success.main" variant="h5" sx={{ fontWeight: '600', marginBottom: 1 }}>
                                    ${credit.myDownstreamSum?.numberWithCommas?.(2) ?? '0.00'}
                                </Typography>
                            </Box> */}
                        </Box>
                        <TextField
                            size="small"
                            placeholder="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getDownstream();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button sx={{ textTransform: 'none' }} onClick={getDownstream}>
                                            Search
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {!loading && downstream.count === 0 && (
                        <Box className={classes.emptyTable}>
                            <Typography variant="font4">You have no down stream yet. Refer some partners!</Typography>
                        </Box>
                    )}
                </Paper>

                <TablePagination
                    component="div"
                    count={downstream.count}
                    page={page}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[1, 5, 10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRows}
                />

                <Box className={classes.downstreams}>
                    {loading &&
                        Array.from(new Array(3)).map((_, i) => {
                            return <Skeleton key={i} variant="rectangular" width="100%" height="60px" />;
                        })}
                    {!loading &&
                        downstream.items?.map?.((partner, i) => {
                            return <DownstreamItem partner={partner} key={i} />;
                        })}
                </Box>

                <TablePagination
                    component="div"
                    count={downstream.count}
                    page={page}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[1, 5, 10, 15, 20, 25]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRows}
                />
            </Container>
        </Box>
    );
}
