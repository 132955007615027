import { makeStyles } from '@mui/styles';

import Background from 'assets/images/backgrounds/white-abstract.jpg';
import theme from 'theme';

const useStyles = makeStyles(() => ({
    content: {
        paddingTop: theme.spacing(2.25),
        paddingBottom: 30,
    },

    list: {
        '& > div:not(:last-child)': {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
        },

        maxHeight: 250,
        overflow: 'scroll',
    },
    listItem: {
        padding: '12px 0px',
    },

    links: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 6,

        '& > a': {
            textDecoration: 'underline',
        },
    },
}));

export default useStyles;
