import { AccountCircle, CalendarMonth, DocumentScanner, HomeMax, LinkSharp, MenuBook } from '@mui/icons-material';
import { BsLadder } from 'react-icons/bs';
import { IoPersonAdd } from 'react-icons/io5';
import { MdWork } from 'react-icons/md';
import { BlogLink } from 'utils/constants';

export const NavMenu = [
    {
        title: 'Home',
        href: 'https://thereliefconsultants.com/',
        external: true,
    },
    {
        title: 'ERC',
        href: 'https://thereliefconsultants.com/erc',
        external: true,
    },
    {
        title: 'Unemployment',
        href: 'https://thereliefconsultants.com/unemployment',
        external: true,
    },
    {
        title: 'Referral Partners',
        href: 'https://thereliefconsultants.com/partners',
    },
    {
        title: 'Contact',
        href: 'https://thereliefconsultants.com/contact',
        external: true,
    },
    {
        title: 'Blog',
        href: BlogLink,
        external: true,
    },
];

export const AuthMenu = [];

export const SideBarMenu = [
    // {
    //     title: 'Onboarding',
    //     icon: <School />,
    //     href: '/courses',
    //     removeAfter: 'ReferralPartner.course.finished',
    // },
    // {
    //     title: 'Dashboard',
    //     icon: <HomeMax />,
    //     href: '/',
    //     requirement: 'ReferralPartner.course.finished',
    // },
    {
        title: 'Referral Tracker',
        icon: <MdWork />,
        href: '/',
        requirement: 'ReferralPartner.course.finished',
    },
    {
        title: 'Referral Links',
        icon: <LinkSharp />,
        href: '/links',
        mobileOnly: true,
        requirement: 'ReferralPartner.course.finished',
    },
    {
        title: 'Sub-RP Network',
        icon: <BsLadder />,
        href: '/down-stream',
    },
    {
        title: 'Submit Manual Lead',
        icon: <IoPersonAdd />,
        href: '/leads',
        requirement: 'ReferralPartner.course.finished',
    },
    {
        title: 'Docusign Agreement',
        icon: <DocumentScanner />,
        href: '/agreements',
        masterCode: true,
    },
    {
        title: 'Profile',
        icon: <AccountCircle />,
        href: '/profile',
    },
    {
        divider: true,
    },
    {
        title: 'Resources',
        icon: <MenuBook />,
        external: true,
        href: 'https://resources.thereliefconsultants.com',
        requirement: 'ReferralPartner.course.finished',
    },
];
