import React from 'react';

// Modules
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './redux/config';

// Material UI
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';

// CSS Imports
import './utils/string';
import './globals.css';

import theme from './theme';

const App = (props) => (
    <Provider store={store}>
        <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Router />
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
