import { GET_FORMS, SET_FORMS } from '../reducers/forms';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import cloneDeep from 'lodash/cloneDeep';
import breakdown7202 from 'utils/breakdown7202';
import { creditBreakdown } from 'utils/credit-breakdown';

export default class FormRedux {
    static get =
        ({ perPage, page, order, orderBy, search }) =>
        async (dispatch, getState) => {
            const user = getState().user;
            try {
                const url = `/forms?perPage=${perPage}&page=${page}&order=${order}&orderBy=${orderBy}&search=${search}&type=self-employment&type=ERC`;

                const response = await axios.get(`${ApiResource.endpoint}/partners${url}`, {
                    headers: {
                        'x-auth': user.authToken,
                    },
                });

                dispatch({
                    payload: response.data,
                    type: GET_FORMS,
                });

                return response;
            } catch (error) {
                throw error;
            }
        };

    static select = (id) => async (dispatch, getState) => {
        const { user, forms } = cloneDeep(getState());
        try {
            const response = await axios.get(`${ApiResource.endpoint}/partners/forms/${id}`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });

            const form = response.data;

            if (form.type === 'ERC') {
                form.breakdown = creditBreakdown(form);
            } else if (form.type === 'self-employment') form.breakdown = breakdown7202(form.forms);

            dispatch({
                payload: { ...forms, selected: form },
                type: SET_FORMS,
            });

            return response;
        } catch (error) {
            throw error;
        }
    };

    static setSelected = (a) => (dispatch, getState) => {
        const form = cloneDeep(a);
        const { forms } = cloneDeep(getState());

        if (form.type === 'ERC') {
            form.breakdown = creditBreakdown(form);
        } else if (form.type === 'self-employment') form.breakdown = breakdown7202(form.forms);

        dispatch({
            payload: { ...forms, selected: form },
            type: SET_FORMS,
        });
    };
}
