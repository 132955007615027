import { GET_LEADS } from '../reducers/leads';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';

export default class LeadsRedux {
    static get =
        ({ perPage, page, order, orderBy, search }) =>
        async (dispatch, getState) => {
            const user = getState().user;
            try {
                const url = `/leads?perPage=${perPage}&page=${page}&order=${order}&orderBy=${orderBy}&search=${search}`;

                const response = await axios.get(`${ApiResource.endpoint}/partners${url}`, {
                    headers: {
                        'x-auth': user.authToken,
                    },
                });

                dispatch({
                    payload: response.data,
                    type: GET_LEADS,
                });

                return response;
            } catch (error) {
                throw error;
            }
        };
}
