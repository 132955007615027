// UI
import { Box, CircularProgress, Collapse, Typography } from '@mui/material';
// Styles
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci';
import useStyles from './styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import checkError from 'utils/check-error';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export default function DownstreamItem({ partner, inner }) {
    const classes = useStyles();
    const authToken = useSelector(({ user }) => user.authToken);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downstreams, setDownstreams] = useState([]);
    const dispatch = useDispatch();

    async function getDownStreams() {
        if (partner.downstreamCount === 0) return;
        if (downstreams.length > 0) return setExpanded(!expanded);

        setLoading(true);
        try {
            const url = `/downstream?code=${partner.code}`;

            const response = await axios.get(`${ApiResource.endpoint}/partners${url}`, {
                headers: {
                    'x-auth': authToken,
                },
            });

            setDownstreams(response.data.items);
            setExpanded(true);
        } catch (error) {
            dispatch(setSnackbar({ message: checkError(error), severity: 'error' }));
        }
        setLoading(false);
    }

    return (
        <Box className={clsx(classes.root, { [classes.inner]: inner })}>
            <Box className={classes.content}>
                <Box onClick={getDownStreams} className={clsx(classes.header, classes.hoverUnderline)}>
                    {loading ? <CircularProgress size={12} /> : null}
                    {partner.downstreamCount > 0 && !loading && expanded ? <CiSquareMinus size={24} /> : null}
                    {partner.downstreamCount > 0 && !loading && !expanded ? <CiSquarePlus size={24} /> : null}

                    <Box>
                        <Typography>Partner</Typography>
                        <Typography variant="font3" fontWeight={'400'}>
                            {partner.User?.name}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.stats}>
                    <Box
                        className={clsx(classes.stat, classes.hoverUnderline)}
                        component={Link}
                        to={`/referral-tracker?search=${partner.User.name}`}
                    >
                        <Typography variant="font4" fontWeight={'300'}>
                            Total Applications
                        </Typography>
                        <Typography variant="font3" fontWeight={'500'}>
                            {partner.applicationCount}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font4" fontWeight={'300'}>
                            Credit in Progress
                        </Typography>
                        <Typography color="success.main" variant="font3" fontWeight={'500'}>
                            ${partner.creditInProgress?.numberWithCommas?.(2) || 0}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font4" fontWeight={'300'}>
                            Credits Submitted
                        </Typography>
                        <Typography color="success.main" variant="font3" fontWeight={'500'}>
                            ${partner.creditSubmitted?.numberWithCommas?.(2) || 0}
                        </Typography>
                    </Box>
                    <Box className={classes.stat}>
                        <Typography variant="font4" fontWeight={'300'}>
                            Credits Received
                        </Typography>
                        <Typography color="success.main" variant="font3" fontWeight={'500'}>
                            ${partner.creditReceived?.numberWithCommas?.(2) || 0}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Collapse in={expanded}>
                <Box className={classes.downstreams}>
                    {downstreams.map((downstreamPartner, i) => {
                        return <DownstreamItem key={i} inner partner={downstreamPartner} />;
                    })}
                </Box>
            </Collapse>
        </Box>
    );
}
