// UI
import { InfoOutlined, Search } from '@mui/icons-material';
import { Box, Button, Container, InputAdornment, Paper, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import useStyles from './styles';
import FormRedux from 'redux/actions/forms';
import axios from 'axios';
import ApiResource from 'integrations/api/api_resource';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { STATUS_TO_READABLE } from 'utils/constants';
import Card from 'components/Card/Card';

const TableHeaders = ['Name', 'Status', 'Referred By', 'Credit', 'Last Updated', 'Started On'];
const translation = {
    Name: 'firstName',
    Status: 'status',
    'Referred By': 'ReferralPartnerId',
    Credit: 'Credit.total',
    'Last Updated': 'updatedAt',
    'Started On': 'createdAt',
};

const CREDITS = [
    {
        key: 'creditInProgress',
        refkey: 'downstreamCreditInProgress',
        masterkey: 'masterCreditInProgress',
        title: 'Credits in Progress',
        tooltip: 'Sum of the credits currently being calculated and assembled by The Relief Consultants',
    },
    {
        key: 'creditSubmitted',
        refkey: 'downstreamCreditSubmitted',
        masterkey: 'masterCreditSubmitted',
        title: 'Credits Submitted',
        tooltip: 'Total amount of credits in which The Relief Consultants have collected payments from',
    },
    {
        key: 'creditReceived',
        refkey: 'downstreamCreditReceived',
        masterkey: 'masterCreditReceived',
        title: 'Credits Received',
        tooltip: 'Total credits submitted to the IRS for processing',
    },
];

export default function ReferralTracker() {
    const classes = useStyles();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [loading, setLoading] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const dispatch = useDispatch();
    const { forms, user } = useSelector(({ forms, user }) => ({ forms, user }));
    const [stats, setStats] = useState('');
    const [search, setSearch] = useState(query.search || '');
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Started On',
    });

    useEffect(() => {
        getForms();
        getStats();
    }, [sort, page, perPage]);

    async function getStats() {
        setLoadingStats(true);
        try {
            const response = await axios.get(`${ApiResource.endpoint}/partners/downstream/credit`, {
                headers: {
                    'x-auth': user.authToken,
                },
            });
            setStats(response.data);
        } catch (error) {
            console.log(error);
        }
        setLoadingStats(false);
    }

    async function getForms() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];
            await dispatch(
                FormRedux.get({
                    perPage,
                    page,
                    order: sort.order,
                    orderBy: orderByTl,
                    search,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Card title="My Credits" className={classes.paper} sx={{ mb: 2 }}>
                    <Box className={classes.stats}>
                        {!loadingStats &&
                            CREDITS.map((keys, i) => {
                                let color = 'success.main';
                                if (!stats[keys.key]) {
                                    color = '#000';
                                }

                                return (
                                    <Box key={i} className={classes.stat}>
                                        {/* Note if 0 turn yellow, green if not 0 */}
                                        <Tooltip arrow placement="top" title={keys.tooltip}>
                                            <Box className={classes.statTitle}>
                                                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                    {keys.title}
                                                </Typography>
                                                <InfoOutlined />
                                            </Box>
                                        </Tooltip>
                                        <Typography variant="h5" color={color} sx={{ fontWeight: '600', marginBottom: 1 }}>
                                            ${stats?.[keys.key]?.numberWithCommas?.(2) || '0.00'}
                                        </Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                </Card>
                <Card title="Sub-RP Network Credits" className={classes.paper} sx={{ mb: 2 }}>
                    <Box className={classes.stats}>
                        {!loadingStats &&
                            CREDITS.map((keys, i) => {
                                const key = !user.ReferralPartner.isMaster ? 'refkey' : 'masterkey';
                                let color = 'success.main';
                                if (!stats[keys[key]]) {
                                    color = 'yellow.main';
                                }

                                return (
                                    <Box key={i} className={classes.stat}>
                                        {/* Note if 0 turn yellow, green if not 0 */}
                                        <Tooltip arrow placement="top" title={keys.tooltip}>
                                            <Box className={classes.statTitle}>
                                                <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                    {keys.title}
                                                </Typography>
                                                <InfoOutlined />
                                            </Box>
                                        </Tooltip>
                                        <Typography variant="h5" color={color} sx={{ fontWeight: '600', marginBottom: 1 }}>
                                            ${stats?.[keys[key]]?.numberWithCommas?.(2) || '0.00'}
                                        </Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                </Card>
                <Paper className={classes.paper}>
                    <Box className={classes.tableActions}>
                        <TextField
                            size="small"
                            placeholder="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getForms();
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button sx={{ textTransform: 'none' }} onClick={getForms}>
                                            Search
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <CustomTable
                        classes={{ tableHeaderCell: classes.tableHeaderCell }}
                        count={forms.count}
                        headers={TableHeaders}
                        onSort={handleOnSort}
                        rowsPerPage={perPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRows={handleChangeRows}
                        initialOrderIndex={3}
                        pagination={forms.count !== 0}
                        rows={
                            loading
                                ? Array.from(new Array(5)).map((_, i) => {
                                      return {
                                          columns: [<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />],
                                      };
                                  })
                                : forms.items.map((item) => {
                                      return {
                                          navigate: { to: `/form/${item.id}`, state: { form: item } },
                                          columns: [
                                              <Typography variant="font2" sx={{ fontWeight: '500' }}>
                                                  {item.firstName} {item.lastName}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {STATUS_TO_READABLE[item.status]}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {item.ReferralPartner?.User?.name}
                                              </Typography>,
                                              <Typography variant="font3" color="success.main" sx={{ fontWeight: '600' }}>
                                                  ${item.Credit?.total?.numberWithCommas(2) || '-'}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {dayjs(item.createdAt).format('MM/DD/YYYY')}
                                              </Typography>,
                                          ],
                                      };
                                  })
                        }
                    />
                    {!loading && forms.count === 0 && (
                        <Box className={classes.emptyTable}>
                            <Typography variant="font4">You have no Deals in Progress.</Typography>
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box>
    );
}
