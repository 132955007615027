// UI
import { Search } from '@mui/icons-material';
import { Box, Button, Container, InputAdornment, Paper, Skeleton, TextField, Typography } from '@mui/material';
import ContactDialog from 'components/Dialogs/ContactDialog/ContactDialog';
import CustomTable from 'components/Widgets/CustomTable/CustomTable';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Styles
import useStyles from './styles';
import LeadsRedux from 'redux/actions/leads';

const TableHeaders = ['Name', 'Status', 'Type Of Lead', 'Last Updated', ''];
const translation = {
    Name: 'metadata.firstName',
    Status: 'status',
    'Type Of Lead': 'type',
    'Last Updated': 'updatedAt',
};

export default function Leads() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { user, leads } = useSelector(({ user, leads }) => ({ user, leads }));
    const dispatch = useDispatch();
    const [contactDialog, setContactDialog] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({
        order: 'DESC',
        orderBy: 'Last Updated',
    });

    useEffect(() => {
        getleads();
    }, [sort, page, perPage]);

    async function getleads() {
        setLoading(true);
        try {
            const orderByTl = translation[sort.orderBy];
            await dispatch(
                LeadsRedux.get({
                    perPage,
                    page,
                    order: sort.order,
                    orderBy: orderByTl,
                    search,
                }),
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleChangePage = (e, page) => {
        setPage(page);
    };

    const handleChangeRows = (e) => {
        setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Box className={classes.root}>
            <ContactDialog
                onSubmit={() => {
                    setContactDialog(false);
                    getleads();
                }}
                type={contactDialog}
                open={Boolean(contactDialog)}
                referralCode={user.ReferralPartner.code}
                onClose={() => setContactDialog(false)}
            />
            <Container className={classes.container} maxWidth="lg">
                <Paper className={classes.paper}>
                    <Box className={classes.tableActions}>
                        <Typography variant="h5" sx={{ fontWeight: '600', marginBottom: 1 }}>
                            My Leads
                        </Typography>
                        <Box className={classes.actions}>
                            <Button
                                variant="contained"
                                onClick={() => setContactDialog('self-employment')}
                                sx={{ marginRight: 1, marginBottom: { xs: 1, md: 0 } }}
                            >
                                Submit Self-Employment Lead
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setContactDialog('ERC')}
                                sx={{ marginRight: 1, marginBottom: { xs: 1, md: 0 } }}
                            >
                                Submit ERC Lead
                            </Button>
                            <TextField
                                size="small"
                                placeholder="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getleads();
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button sx={{ textTransform: 'none' }} onClick={getleads}>
                                                Search
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>

                    <CustomTable
                        classes={{ tableHeaderCell: classes.tableHeaderCell }}
                        count={leads.count}
                        headers={TableHeaders}
                        onSort={handleOnSort}
                        rowsPerPage={perPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRows={handleChangeRows}
                        initialOrderIndex={3}
                        pagination={leads.count !== 0}
                        rows={
                            loading
                                ? Array.from(new Array(5)).map((_, i) => {
                                      return {
                                          columns: [<Skeleton />, <Skeleton />, <Skeleton />, <Skeleton />],
                                      };
                                  })
                                : leads.items.map((item) => {
                                      const toUrl = `/form/${item.metadata?.formId}`;
                                      const disabled = !item.metadata.formId;

                                      return {
                                          columns: [
                                              <Typography variant="font2" sx={{ fontWeight: '500' }}>
                                                  {item.metadata.firstName} {item.metadata.lastName}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {item.status}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {item.type}
                                              </Typography>,
                                              <Typography variant="font3" sx={{ fontWeight: '400' }}>
                                                  {dayjs(item.updatedAt).format('MM/DD/YYYY')}
                                              </Typography>,
                                              <Button disabled={disabled} component={Link} to={toUrl}>
                                                  {disabled ? 'Not Converted Yet' : 'View Application'}
                                              </Button>,
                                          ],
                                      };
                                  })
                        }
                    />
                    {!loading && leads.count === 0 && (
                        <Box className={classes.emptyTable}>
                            <Typography variant="font4">You have no leads yet.</Typography>
                        </Box>
                    )}
                </Paper>
            </Container>
        </Box>
    );
}
